import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

const CookiePolicy = () => {
  return (
    <MDBContainer className="py-5">
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBTypography tag="h1" variant="display-4" className="text-center">
            Cookie Policy
          </MDBTypography>
          <MDBTypography tag="p" className="text-center text-muted">
            This Cookie Policy was last updated on October 8, 2024.
          </MDBTypography>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="8" className="mx-auto">
          <MDBCard className="mb-4 shadow-sm">
            <MDBCardBody>
              <MDBTypography tag="h2" variant="h5" className="mb-3">
                1. Introduction
              </MDBTypography>
              <p>
                On this website we use functional cookies. In this policy, we
                inform you about the use of these cookies.
              </p>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="mb-4 shadow-sm">
            <MDBCardBody>
              <MDBTypography tag="h2" variant="h5" className="mb-3">
                2. What are cookies?
              </MDBTypography>
              <p>
                A cookie is a small simple file that is sent along with pages of
                this website and stored by your browser on the hard drive of
                your computer or another device. The information stored therein
                may be returned to our servers or to the servers of the relevant
                third parties during a subsequent visit.
              </p>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="mb-4 shadow-sm">
            <MDBCardBody>
              <MDBTypography tag="h2" variant="h5" className="mb-3">
                3. Technical or functional cookies
              </MDBTypography>
              <p>
                The cookies used on this website ensure that certain parts of
                the website work properly and that your user preferences remain
                known. By placing functional cookies, we make it easier for you
                to visit our website and use all basic functionalities. For
                example, the cookies ensure that as a visitor you can upload
                documents and review status overviews.
              </p>
              <p>
                As these cookies are necessary to offer you a functional
                website, we may place these cookies without your consent.
              </p>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="mb-4 shadow-sm">
            <MDBCardBody>
              <MDBTypography tag="h2" variant="h5" className="mb-3">
                4. Cookies used on this website
              </MDBTypography>
              <p>
                On our website, only session cookies are used, which expire
                after one user session.
              </p>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="mb-4 shadow-sm">
            <MDBCardBody>
              <MDBTypography tag="h2" variant="h5" className="mb-3">
                5. Contact details
              </MDBTypography>
              <p>
                For questions and/or comments about our Cookie Policy and this
                statement, please contact us by using the following contact
                details:
              </p>
              <ul>
                <li>STM Solutions</li>
                <li>
                  Prins Willem Alexanderhof 5, 2595 BE, The Hague, Netherlands
                </li>
                <li>
                  Website:{" "}
                  <a
                    href="https://www.hub.stm-solutions.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.hub.stm-solutions.org
                  </a>
                </li>
                <li>
                  Email:{" "}
                  <a href="mailto:dpo@stm-solutions.org">
                    dpo@stm-solutions.org
                  </a>
                </li>
              </ul>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default CookiePolicy;
